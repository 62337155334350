import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import ArticlesList from '../components/articles/ArticlesList';
import PageHero from '../components/hero/PageHero';
import ArticlesNavContainer from '../components/articles/ArticlesNavContainer';
import ArticlesNav from '../components/articles/ArticlesNav';
import heroImage from '../images/case-studies-hero.jpg';
import { getSEOTextByTag } from './index';

const CaseStudies = ({ data }) => (
  <Layout>
    <SEO
      title={getSEOTextByTag(data.seo, 'title') || 'Seattle-based Software Services'}
      description="New Normal web and mobile development case studies. We are experts in Seattle."
    />
    <PageHero image={heroImage}>
      We deliver technologies today that will be commonplace tomorrow.
    </PageHero>
    <main>
      <ArticlesList data={data.allMarkdownRemark} />
      <ArticlesNavContainer>
        <ArticlesNav title="Next Step:" link="/#contact" name="Get in touch" />
      </ArticlesNavContainer>
    </main>
  </Layout>
);

export default CaseStudies;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//case-studies//"}
        frontmatter: {
          visibility: {eq: "yes"}
        }
      },
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        ...MarkdownPostNode
      }
    }
    seo: allMarkdownRemark(
      limit: 10
      filter: {
        fields: {
          slug: {regex: "//seo//"}
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            text
          }
        }
      }
    }
  }
`;
